import { Stack, VStack, Heading, Text, Box, HStack, Image, Link, Input, Textarea, Button,
  Divider, Flex, Spinner,
  Table,
  Thead,
  Tbody,
  Tfoot,
  Tr,
  Th,
  Td,
  TableCaption,
  TableContainer } from "@chakra-ui/react"
import { BiTrain, BiBus, BiSun, BiSolidPlaneTakeOff, BiHome, BiSolidFile, BiCaretRight, BiAlarm, BiCalendar, BiCertification, BiCheckCircle, BiMinus } from "react-icons/bi";

import { useContext } from "react";
import { useParams } from "react-router-dom";
import { useLocation } from 'react-router';
import { useEffect, useState } from "react";
import useShowToast from "../../hooks/useShowToast";
import { TOKEN_KEY, USERID_KEY } from "../../utility/constants";

import { HouseContext } from "../../components/pats/HouseContext";
import { useRecoilState } from "recoil";
import listPriceAtom from "atoms/listPriceAtom";
import { getSalesListPrice, getSalesListPriceReport } from "restcall/ListPrice";
import { useNavigate } from "react-router-dom";
//import Form from "./Form";
import salesAtom from "../../atoms/salesAtom";
import imgTest from './img/Esterno.jpeg';
import PhotoGallery from "./photoGallery/photoGallery";
import { Document, Page } from 'react-pdf'
import { pdfjs } from 'react-pdf';

//pdfjs.GlobalWorkerOptions.workerSrc = new URL(
//  'pdfjs-dist/build/pdf.worker.min.js',
//  import.meta.url,
//).toString();

const FacilityDetails = (props) => {

  const {propertyId} = useParams();
	const [listPrice, setListPrice] = useRecoilState(listPriceAtom);
	const [loading, setLoading] = useState(true);
	const [reportListPrice, setReportListPrice] = useState();
  const [reportName, setReportName] = useState();
    const showToast = useShowToast();
	const navigate = useNavigate();

  const { houses } = useContext(HouseContext);
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);

  function onDocumentLoadSuccess(numPages) {
    reportListPrice ? setNumPages(1):setNumPages(0);
  }
  const searchedHouse = houses.find(house=> house.id=== propertyId)

//  const [sales, setSales] = useRecoilState(salesAtom);
//  const selectedSales = sales.find(sale=> sale.id=== propertyId);
  let { state } = useLocation();
//  const { state } = props.location;
  const facilityItem = state.item;


  const unitImages = {
      images: [
        {
          original:
          `data:image/jpeg;base64,${facilityItem?.image1}`,
          thumbnail:
          `data:image/jpeg;base64,${facilityItem?.image1}`,
          originalWidth: 450,
          originalHeight: 300
        },
        {
          original:
          `data:image/jpeg;base64,${facilityItem?.image2}`,
          thumbnail:
          `data:image/jpeg;base64,${facilityItem?.image2}`,
          originalWidth: 450,
          originalHeight: 300
        },
        {
          original:
          `data:image/jpeg;base64,${facilityItem?.image3}`,
          thumbnail:
          `data:image/jpeg;base64,${facilityItem?.image3}`,
          originalWidth: 450,
          originalHeight: 300
        },
        {
          original:
          `data:image/jpeg;base64,${facilityItem?.image4}`,
          thumbnail:
          `data:image/jpeg;base64,${facilityItem?.image4}`,
          originalWidth: 450,
          originalHeight: 300
        }
      ]
  };

  const unitIcons = {
    images: [
      {
        original:
        `data:image/jpeg;base64,${facilityItem?.imageicon1}`,
      },
      {
        original:
        `data:image/jpeg;base64,${facilityItem?.imageicon2}`,
      },
      {
        original:
        `data:image/jpeg;base64,${facilityItem?.imageicon3}`,
      },
      {
        original:
        `data:image/jpeg;base64,${facilityItem?.imageicon4}`,
      },
      {
        original:
        `data:image/jpeg;base64,${facilityItem?.imageicon5}`,
      },
      {
        original:
        `data:image/jpeg;base64,${facilityItem?.imageicon6}`,
      },
      {
        original:
        `data:image/jpeg;base64,${facilityItem?.imageicon7}`,
      },
      {
        original:
        `data:image/jpeg;base64,${facilityItem?.imageicon8}`,
      },
      {
        original:
        `data:image/jpeg;base64,${facilityItem?.imageicon9}`,
      },
      {
        original:
        `data:image/jpeg;base64,${facilityItem?.imageicon10}`,
      },
      {
        original:
        `data:image/jpeg;base64,${facilityItem?.imageicon11}`,
      },
      {
        original:
        `data:image/jpeg;base64,${facilityItem?.imageicon12}`,
      },
      {
        original:
        `data:image/jpeg;base64,${facilityItem?.imageicon13}`,
      },
      {
        original:
        `data:image/jpeg;base64,${facilityItem?.imageicon14}`,
      },
      {
        original:
        `data:image/jpeg;base64,${facilityItem?.imageicon15}`,
      },
      {
        original:
        `data:image/jpeg;base64,${facilityItem?.imageicon16}`,
      },
      {
        original:
        `data:image/jpeg;base64,${facilityItem?.imageicon17}`,
      },
      {
        original:
        `data:image/jpeg;base64,${facilityItem?.imageicon18}`,
      },
      {
        original:
        `data:image/jpeg;base64,${facilityItem?.imageicon19}`,
      },
      {
        original:
        `data:image/jpeg;base64,${facilityItem?.imageicon20}`,
      },
      {
        original:
        `data:image/jpeg;base64,${facilityItem?.imageicon21}`,
      },
      {
        original:
        `data:image/jpeg;base64,${facilityItem?.imageicon22}`,
      },
      {
        original:
        `data:image/jpeg;base64,${facilityItem?.imageicon23}`,
      },
      {
        original:
        `data:image/jpeg;base64,${facilityItem?.imageicon24}`,
      },
      {
        original:
        `data:image/jpeg;base64,${facilityItem?.imageicon25}`,
      }
    ]
};

  
  useEffect(() => {
    const getListPrice = async () => {
      setLoading(true);
//			setPosts([]);
//			setSales([]);
      try {
//				const res = await fetch("/api/posts/feed");
        const res = await getSalesListPrice(propertyId);
        console.log(res);

        if (res.status && res.status !== 200) {
          showToast("Error:", res.status+" - "+res.statusText, "error");
          if(res.status === 401) {
            localStorage.removeItem("user-threads");
            localStorage.removeItem(TOKEN_KEY);
            localStorage.removeItem(USERID_KEY);
            showToast("Attention", "Session expired ", "error");
            navigate('/', { replace: true });
          }
          return;
        }
        const data = await res.json();
        console.log(data);
//				setPosts(data);
        setListPrice(data.records[0]);
        // Get the report
        const result = await getSalesListPriceReport(propertyId);
        if (res.status && res.status !== 200) {
          showToast("Error:", data.title+" - "+data.detail, "error");
          if(data.status === 401) {
            localStorage.removeItem("user-threads");
            localStorage.removeItem(TOKEN_KEY);
            localStorage.removeItem(USERID_KEY);
            showToast("Attention", "Session expired ", "error");
            navigate('/', { replace: true });
          }
          return;
        }
        const data2 = await result.json();
        console.log(data2);

        setReportListPrice(data2.exportFile);
        setReportName(data2.exportFileName);

      } catch (error) {
//				showToast("Error", error.message, "error");
        console.log(error);
        localStorage.removeItem("user-threads");
        localStorage.removeItem(TOKEN_KEY);
        localStorage.removeItem(USERID_KEY);
        showToast("Attention", "Timeout or host not reachable", "error");
  
        navigate('/', { replace: true });
//				navigate('/auth');
      } finally {
        setLoading(false);
      }
    };
    getListPrice();
    window.scrollTo(0, 0);
//	}, [showToast, setPosts]);
  }, [showToast, setListPrice]);


  return (
        <>
      <Stack direction={{base:'column', lg: 'row'}} gap='6' align='flex-start' p='5'>
        {loading && (
          <Flex justify='center'>
            <Spinner size='xl' />
          </Flex>
        )}
        {!loading && (<Stack spacing={3} direction="column">
{/*          <Image src={imgTest} alt='Facility' /> */}
{/*          <Image src={`data:image/jpeg;base64,${facilityItem.image1}`} alt='Facility' /> */}
          <PhotoGallery images={unitImages.images} />

        </Stack>)}


        {!loading && (<VStack spacing={3} direction="column" align='left'>
            <Box>
                  <Heading fontSize="24px" fontWeight="extrabold" letterSpacing="tight" color="pink.500">
                  {facilityItem.facilitiesname}
                  </Heading>
            </Box>

            <Text fontSize="13px" color="grey">
              {facilityItem.Address1} - {facilityItem.Postal} {facilityItem.City} ({facilityItem.Region})
            </Text>
            <Divider my="2.5" />
            <HStack >
              <BiCertification style={{ color: "#D53F8C" }} />
              <Text fontSize="16px">{facilityItem.FacilityCategory}</Text>
            </HStack>
            <HStack >
              <BiBus style={{ color: "#D53F8C" }} alt='BusStop'/>
              <Text fontSize="xs" >BusStop: {facilityItem.DistanceFromBusStop}</Text>
              <BiSun style={{ color: "#D53F8C" }} />
              <Text fontSize="xs" >Beach: {facilityItem.DistanceFromBeach}</Text>
              <BiTrain style={{ color: "#D53F8C" }} />
              <Text fontSize="xs" >TrainStation: {facilityItem.DistanceFromTrainStation}</Text>
              <BiSolidPlaneTakeOff style={{ color: "#D53F8C" }} />
              <Text fontSize="xs">Airport: {facilityItem.DistanceFromAirport}</Text>
            </HStack>
            <HStack >
            <BiHome style={{ color: "#D53F8C" }} />
            <Link href={facilityItem.URL} isExternal>{facilityItem.URL}</Link>
            </HStack>
            <Stack maxW={50} direction={{base:'column', lg: 'row'}} gap='6' align='flex-start'>
              <VStack align='left' maxW='70%'>
                <Stack py='10px' spacing={{sm: '3', md: '5'}} direction={{base: 'row', md: 'row'}}>
                  {/* Add more icons here */}
                  {unitIcons.images.map((icon, index) => (
                      icon.original !== undefined ? <Image src={icon.original} width={50}/> : <></>
                  ))}
                </Stack>
              </VStack>
            </Stack>
            <Stack direction={{base:'row', lg: 'row'}} gap='6' align='flex-start'>
              <HStack >
                <BiSolidFile style={{ color: "#D53F8C" }} />
                <Link href={`data:application/pdf;base64,${reportListPrice}`} download={reportName}>Download Datasheet and PriceList Year {facilityItem.Year}</Link>
              </HStack>
            </Stack>
        </VStack>
        )}


        
{/*
      <Stack direction={{base: 'column', md: 'row'}} justify='space-between' align={{md: 'center'}}  my='28px'>
        <Heading fontSize='22px'>{searchedHouse.name}</Heading>
          <Text fontSize='15px'>{searchedHouse.address}</Text>
        </Box>
        
        <HStack>
          <Text px='3' borderRadius='full' bg='green.300'>{searchedHouse.type}</Text>
          <Text px='3' borderRadius='full' bg='purple.300'>{searchedHouse.country}</Text>
        </HStack>

        <Text fontWeight="extrabold" fontSize="20px" color="pink.500">Rs. {searchedHouse.price}</Text>
      </Stack>

      <Stack direction={{base:'column', lg: 'row'}} gap='6' align='flex-start'>
        <VStack align='left' maxW='640px'>
          <Image src={searchedHouse.imageLg} alt='house' />

          <Stack py='10px' spacing={{sm: '3', md: '5'}} direction={{base: 'column', md: 'row'}}>
            <HStack>
                <BiBed style={{ color: "#D53F8C" }} />
                <Text fontSize="14px">{searchedHouse.bedrooms} Bedrooms</Text>
            </HStack>

            <HStack>
                <BiBath style={{ color: "#D53F8C" }} />
                <Text fontSize="14px">{searchedHouse.bathrooms} Bathrooms</Text>
            </HStack>

            <HStack>
                <BiArea style={{ color: "#D53F8C" }} />
                <Text fontSize="14px">{searchedHouse.surface}</Text>
            </HStack>
          </Stack>
        
          <Text fontSize='15px'>{searchedHouse.description}</Text>
      
        </VStack>
        
{/*        <Form searchedHouse={searchedHouse} />*/}
      </Stack>
      <Stack direction={{base:'column', lg: 'row'}} gap='6' align='flex-start' p='5'>
        <Stack spacing={3} direction="column">
              <Box>
              <Heading fontSize='15px'>Tourist Facility Features</Heading>
              </Box>
              {listPrice.Pat_SA_FacilitiesService?.map(item => {
                if(item.Type.id === 'S')
                  return (<>
                  <HStack>
                      {item.IsServicePresent ? <BiCheckCircle style={{ color: "#D53F8C" }} /> : <BiMinus style={{ color: "#D53F8C" }} />}
                      <Text fontSize="14px">{item.Pat_FacilitiesService_ID.identifier}</Text>
                      {item.Price ?
                        <>
                        <Stack direction={{base: 'column', md: 'row'}} justify='space-between' align={{md: 'center'}}  my='28px'>
                        <Text fontSize='xs' as='b'>&euro;{item.Price}</Text>
                        <Text fontSize='xs' as='b'>{item.Description}</Text>
                        </Stack>
                        </>
                      : <></>}
                  </HStack>
                  </>
                  );
                else
                  return(<></>);
              })}
            </Stack>
            <Stack spacing={3} direction="column">
            <Box>
              <Heading fontSize='15px'>Unit Features</Heading>
              </Box>
              {listPrice.Pat_SA_FacilitiesService?.map(item => {
                if(item.Type.id === 'U')
                  return (<HStack>
                      {!('N.A.'===item.ServiceRequestType?.identifier) ? <BiCheckCircle style={{ color: "#D53F8C" }} /> : <BiMinus style={{ color: "#D53F8C" }} />}
                      <Text fontSize="14px">{item.Pat_FacilitiesService_ID.identifier}</Text>
                      <Stack direction={{base: 'column', md: 'row'}} justify='space-between' align={{md: 'center'}}  my='28px'>
                        <Text fontSize='xs' as={!('N.A.'===item.ServiceRequestType?.identifier) ? 'b':''}>{item.ServiceRequestType?.identifier}</Text>
                        <Text fontSize='xs' as='b'>
                          {item.PriceXNight ? '€'+item.PriceXNight+' X Pax X Night':''}
                        {item.PriceXUnit ? '€'+item.PriceXUnit+' X Pax X Unit':''} 
                        {item.PriceForfait ? '€'+item.PriceForfait+' Forfait X Stay':''}
                        </Text>
                        <Text fontSize='xs' as='b'>{item.IsPaymentAtFacilities ? 'Payment on Spot':''}</Text>
                        </Stack>
                  </HStack>);
                else
                  return(<></>);
              })}
            </Stack>
            <Stack spacing={3} direction="column">
              <Box>
                <Heading fontSize='15px'>Treatments / Board</Heading>
              </Box>
              {listPrice.Pat_SA_ServicesOnTreatment?.map(item => {
                  return (<HStack>
                      <BiCaretRight style={{ color: "#D53F8C" }} fontSize='sm'/>
                      <Text fontSize="sm">{item.Pat_Supplement_ID.identifier}</Text>
                      <Stack direction={{base: 'row', md: 'row'}} justify='space-between' align={{md: 'center'}}  my='28px'>
                        <Text fontSize='xs' >{item?.ServicesComprised?.identifier}</Text>
                        {item?.Period ? <BiCalendar style={{ color: "#D53F8C" }} fontSize='sm'/> : <></>}
                        <Text fontSize='xs' >{item?.Period ? 'Guaranteed: '+item?.Period:'' }</Text>
                        {item?.TimeTables ? <BiAlarm style={{ color: "#D53F8C" }} fontSize='sm'/> : <></>}
                        <Text fontSize='xs' >{item?.TimeTables ? 'Hours: '+item?.TimeTables:''}</Text>
                        </Stack>
                  </HStack>);
              })}
            </Stack>
            <Stack spacing={3} direction="column">
              <Box>
                <Heading fontSize='15px'>Special Offers: {listPrice?.Pat_OfferApplicability_ID?.identifier}</Heading>
              </Box>
              {listPrice.Pat_SA_SpecialOffers?.map(item => {
                  return (<HStack>
                      <BiCaretRight style={{ color: "#D53F8C" }}/>
                      <Text fontSize="sm">{item?.Text.replace(/#/g,'')}</Text>
                      <Stack direction={{base: 'row', md: 'row'}} justify='space-between' align={{md: 'center'}}  my='28px'>
                        <Text fontSize='xs'>{item?.ValueNumber}</Text>
                        {item?.Period ? <BiCalendar style={{ color: "#D53F8C" }} fontSize='sm'/> : <></>}
                        <Text fontSize='xs'>{item?.Period ? 'Validity: '+item?.Period:'' }</Text>
                        </Stack>
                  </HStack>);
              })}
            </Stack>

            <Stack spacing={3} direction="column">
              <Box>
                <Heading fontSize='15px'>Important Notes</Heading>
              </Box>
              {listPrice.Pat_SA_Notes?.map(item => {
                  return (<HStack>
                      <BiCaretRight style={{ color: "#D53F8C" }}/>
                      <Text fontSize="sm">{item?.Text.replace(/#/g,'')}</Text>
                  </HStack>);
              })}
            </Stack>


      </Stack>
      
{/*        
      <Stack direction={{base:'column', lg: 'row'}} gap='6' align='flex-start'>
      <Link href={`data:application/pdf;base64,${reportListPrice}`} download={reportName} target="_blank">Download Report</Link>
      <iframe
      src={`data:application/pdf;base64,${
      reportListPrice
      }`}

      />
 <embed src={`data:application/pdf;base64,${reportListPrice}`} type="application/pdf"/>
      </Stack>
*/}

{/* 
      {reportListPrice !== undefined && reportListPrice !== null ? 
        <>
        <Document file={reportListPrice ? `data:application/pdf;base64,${reportListPrice}`:`` } onLoadSuccess={onDocumentLoadSuccess}>
         <Page />
       </Document>
        <p>
          Page {pageNumber} of {numPages}
        </p>
        </>
      : <></>}
*/}      

      

{/*
  <TableContainer>
  <Table variant='simple'>
    <TableCaption>Imperial to metric conversion factors</TableCaption>
    <Thead>
      <Tr>
        <Th>To convert</Th>
        <Th>into</Th>
        <Th isNumeric>multiply by</Th>
      </Tr>
    </Thead>
    <Tbody>
      <Tr>
        <Td>inches</Td>
        <Td>millimetres (mm)</Td>
        <Td isNumeric>25.4</Td>
      </Tr>
      <Tr>
        <Td>feet</Td>
        <Td>centimetres (cm)</Td>
        <Td isNumeric>30.48</Td>
      </Tr>
      <Tr>
        <Td>yards</Td>
        <Td>metres (m)</Td>
        <Td isNumeric>0.91444</Td>
      </Tr>
    </Tbody>
    <Tfoot>
      <Tr>
        <Th>To convert</Th>
        <Th>into</Th>
        <Th isNumeric>multiply by</Th>
      </Tr>
    </Tfoot>
  </Table>
</TableContainer>        
*/}
        </>
  )
}

export default FacilityDetails;