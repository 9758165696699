import { Box, Flex, Spinner } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import useShowToast from "../../hooks/useShowToast";
//import Post from "../components/Post";
import Sale from "../../components/pats/Sale";
import { useRecoilState } from "recoil";
//import postsAtom from "../atoms/postsAtom";
//import SuggestedUsers from "../components/SuggestedUsers";
import { getSalesCustomer } from "../../restcall/SalesCustomer";
//import { API_SALES_CUSTOMER, ENDPOINT_URL, FIELD_CUSTOMER_ID, STR_EQ, STR_FILTER, TOKEN_KEY, USERID_KEY } from "../assets/constants";
import salesAtom from "../../atoms/salesAtom";
import searchTextAtom from "../../atoms/searchTextAtom";
import { TOKEN_KEY, USERID_KEY } from "../../utility/constants";
import { useNavigate } from "react-router-dom";
//import Overview from "../views/admin/profile";
import Facilities from "../../components/pats/Facilities";
import HouseProvider from "../../components/pats/HouseContext";
import HeaderLinks from "./NavbarLinksAdmin";

const HomePage = () => {
//	const [posts, setPosts] = useRecoilState(postsAtom);
	const [sales, setSales] = useRecoilState(salesAtom);
	const [searchText, setSearchText] = useRecoilState(searchTextAtom);
	const [loading, setLoading] = useState(true);
	const showToast = useShowToast();
	const navigate = useNavigate();

//	setSearchText("");

	useEffect(() => {
		const getSales = async () => {
			setLoading(true);
//			setPosts([]);
//			setSales([]);
			try {
//				const res = await fetch("/api/posts/feed");
//				const res = await getSalesCustomer(searchText);
				const res = await getSalesCustomer();
				console.log(res);

				if (res.status && res.status !== 200) {
//					showToast("Error:", data.title+" - "+data.detail, "error");
					showToast("Error:", res.status+" - "+res.statusText, "error");
//					if(data.status === 401) {
						localStorage.removeItem("user-threads");
						localStorage.removeItem("user-logged");
						localStorage.removeItem(TOKEN_KEY);
						localStorage.removeItem(USERID_KEY);
//						showToast("Attention", "Session expired ", "error");
						navigate('/Auth', { replace: true });
//					}
					return;
				}
				const data = await res.json();
				console.log(data);
//				setPosts(data);
				setSales(data.records);
			} catch (error) {
//				showToast("Error", error.message, "error");
				console.log(error);
				localStorage.removeItem("user-threads");
				localStorage.removeItem(TOKEN_KEY);
				localStorage.removeItem(USERID_KEY);
				showToast("Attention", "Timeout or host not reachable", "error");
	
				navigate('/', { replace: true });
//				navigate('/auth');
			} finally {
				setLoading(false);
			}
		};
		getSales();
//	}, [showToast, setPosts]);
	}, [showToast, setSales]);

	return (
		<HouseProvider>
			<HeaderLinks
			/>
	    	{loading && (
				<Flex justify='center'>
					<Spinner size='xl' />
				</Flex>
			)}
{/*		
		<Flex gap='10' alignItems={"flex-start"}>
			<Box flex={70}>
				{!loading && sales.length === 0 && <h1>Facilities not found</h1>}

				{loading && (
					<Flex justify='center'>
						<Spinner size='xl' />
					</Flex>
				)}

				{sales.map((sale) => (
					<Sale key={sale._id} sale={sale} postedBy={sale.id} />
				))}
			</Box>
			<Box
				flex={30}
				display={{
					base: "none",
					md: "block",
				}}
			>
*/}			
{/*				<SuggestedUsers /> */}
{/*			</Box> */}
{/*		<Overview /> 
		</Flex>
*/}
		<Facilities items={sales}></Facilities> 
		</HouseProvider>
	);
};

export default HomePage;
