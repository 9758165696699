import {
	Flex,
	Box,
	FormControl,
	FormLabel,
	Input,
	InputGroup,
	InputRightElement,
	Stack,
	Button,
	Heading,
	Text,
	useColorModeValue,
	Link,
} from "@chakra-ui/react";
import { useState } from "react";
import { ViewIcon, ViewOffIcon } from "@chakra-ui/icons";
import { useSetRecoilState } from "recoil";
import authScreenAtom from "../../atoms/authAtom";
import useShowToast from "../../hooks/useShowToast";
import userAtom from "../../atoms/userAtom";
import userLoggedAtom from "../../atoms/userLoggedAtom";
import { API_LOGIN, CLIENT_ID, ENDPOINT_URL, LANGUAGE, ORGANIZATION_ID, ROLE_ID, TOKEN_KEY, USERID_KEY, WAREHOUSE_ID } from "../../utility/constants";

export default function LoginCard() {
	const [showPassword, setShowPassword] = useState(false);
	const setAuthScreen = useSetRecoilState(authScreenAtom);
	const setUser = useSetRecoilState(userAtom);
	const setUserLogged = useSetRecoilState(userLoggedAtom);
	const [loading, setLoading] = useState(false);

	const [inputs, setInputs] = useState({
		username: "",
		password: "",
	});
	const showToast = useShowToast();
	const handleLogin = async () => {
		setLoading(true);
		try {
			const res = await fetch(ENDPOINT_URL+API_LOGIN, {
				method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Accept': '*/*',
					'Access-Encoding': 'gzip, deflate, br',
					'Connection': 'keep-alive',
				},
				body: JSON.stringify(
					{
						'userName':inputs.username,
						'password':inputs.password,
						'parameters': {
						'clientId': CLIENT_ID,
						'organizationId': ORGANIZATION_ID,
						'roleId': ROLE_ID,
						'warehouseId': WAREHOUSE_ID,
						'language': LANGUAGE
						}
					}					
					),
			});
			const data = await res.json();
			if (data.status && data.status !== 200) {
				showToast("Error:", data.title+" - Invalid Username or Password - "+data.detail, "error");
				return;
			}
			console.log(data);
			localStorage.setItem("user-threads", JSON.stringify(data));
			localStorage.setItem(TOKEN_KEY, data.token);
			localStorage.setItem(USERID_KEY, data.userId);
			localStorage.setItem("user-logged", JSON.stringify(inputs.username));
			setUser(data);
			setUserLogged(inputs.username);
		} catch (error) {
			console.log(error);
			showToast("Attention", "Timeout or host not reachable", "error");
			return;
		} finally {
			setLoading(false);
		}
	};
	return (
		<Flex align={"center"} justify={"center"}>
			<Stack spacing={8} mx={"auto"} maxW={"lg"} py={12} px={6}>
				<Stack align={"center"}>
					<Heading fontSize={"4xl"} textAlign={"center"}>
						Login
					</Heading>
				</Stack>
				<Box
					rounded={"lg"}
					bg={useColorModeValue("white", "gray.dark")}
					boxShadow={"lg"}
					p={8}
					w={{
						base: "full",
						sm: "400px",
					}}
				>
					<Stack spacing={4} p={4}>
						<FormControl isRequired>
							<FormLabel>Username</FormLabel>
							<Input
								type='text'
								value={inputs.username}
								onChange={(e) => setInputs((inputs) => ({ ...inputs, username: e.target.value }))}
							/>
						</FormControl>
						<FormControl isRequired>
							<FormLabel>Password</FormLabel>
							<InputGroup>
								<Input
									type={showPassword ? "text" : "password"}
									value={inputs.password}
									onChange={(e) => setInputs((inputs) => ({ ...inputs, password: e.target.value }))}
								/>
								<InputRightElement h={"full"}>
									<Button
										variant={"ghost"}
										onClick={() => setShowPassword((showPassword) => !showPassword)}
									>
										{showPassword ? <ViewIcon /> : <ViewOffIcon />}
									</Button>
								</InputRightElement>
							</InputGroup>
						</FormControl>
						<Stack spacing={10} pt={2}>
							<Button
								loadingText='Logging in'
								size='lg'
								bg={useColorModeValue("gray.600", "gray.700")}
								color={"white"}
								_hover={{
									bg: useColorModeValue("gray.700", "gray.800"),
								}}
								onClick={handleLogin}
								isLoading={loading}
							>
								Login
							</Button>
						</Stack>
						<Stack pt={6}>
							<Text align={"center"}>
								Powered by{" "}
								<Link color={"blue.400"} href='https://www.dyensisoftware.it' isExternal >
									Dyens&igrave; Software
								</Link>
							</Text>
						</Stack>
					</Stack>
				</Box>
			</Stack>
		</Flex>
	);
}
