import {
    VStack,
    Divider,
    Heading,
    HStack,
    Image,
    Stack,
    Text,
    Flex,
  } from "@chakra-ui/react";
  import { BiBed, BiBath, BiArea, BiCertification, BiCalendar } from "react-icons/bi";
  
  const Facility = ({ facilityItem }) => {
    return (
      <Flex justify='center' align='center'>
          <Stack justify='center' width="300px" bg="white" boxShadow="xl" borderRadius="xl">
          <Image src={`data:image/jpeg;base64,${facilityItem.image1}`}
             h='170' maxW='290' alt='facilities' />
  
          <VStack p='4' align='left'>
              <Heading fontSize="24px" fontWeight="extrabold" letterSpacing="tight" color="pink.500">
              {facilityItem.facilitiesname}
              </Heading>
    
              <Text fontSize="13px" color="grey">
              {facilityItem.Address1} - {facilityItem.Postal} {facilityItem.City} ({facilityItem.Region})
              </Text>
  
              <Divider my="2.5" />
  
              <HStack >
                  <BiCertification style={{ color: "#D53F8C" }} />
                  <Text fontSize="16px">{facilityItem.FacilityCategory}</Text>
                  <BiCalendar style={{ color: "#D53F8C" }} align="right"/>
                  <Text fontSize="sm" >Year {facilityItem.Year}</Text>
              </HStack>
{/* 
              <Divider my="2.5" />
  
              <HStack spacing="5">
              <HStack>
                  <BiBed style={{ color: "#D53F8C" }} />
                  <Text fontSize="12px">{facilityItem.bedrooms}</Text>
              </HStack>
  
              <HStack>
                  <BiBath style={{ color: "#D53F8C" }} />
                  <Text fontSize="12px">{facilityItem.bathrooms}</Text>
              </HStack>
  
              <HStack>
                  <BiArea style={{ color: "#D53F8C" }} />
                  <Text fontSize="12px">{facilityItem.surface}</Text>
              </HStack>
              </HStack>
*/}              
  
          </VStack>
          </Stack>
      </Flex>
    );
  };
  
  export default Facility;