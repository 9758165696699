import {
	Button,
	Text
  } from '@chakra-ui/react';
  
import { useSetRecoilState, useRecoilState } from "recoil";
import userAtom from "../../../atoms/userAtom";
import userLoggedAtom from "../../../atoms/userLoggedAtom";
import useShowToast from "../../../hooks/useShowToast";
import { FiLogOut } from "react-icons/fi";
import { API_LOGIN,API_LOGOUT, ENDPOINT_URL } from "utility/constants";
import { useNavigate } from "react-router-dom";

const LogoutButton = () => {
	const setUser = useSetRecoilState(userAtom);
	const setUserLogged = useSetRecoilState(userLoggedAtom);
	const showToast = useShowToast();
	const navigate = useNavigate();

	const [user, setUser2] = useRecoilState(userAtom);


	const handleLogout = async () => {
		try {
			const res = await fetch(ENDPOINT_URL+API_LOGOUT, {
//			const res = await fetch(ENDPOINT_URL+API_LOGIN, {
					method: 'POST',
				headers: {
					'Content-Type': 'application/json',
					'Accept': '*/*',
					'Access-Encoding': 'gzip, deflate, br',
					'Connection': 'keep-alive',
				},
				body: JSON.stringify(
					{
						'token':user.token
					}					
				),
			});

//			if (res?.status !== 200) {
//				showToast("Error", "Failed to logout", "error");
//				return;
//			}

//			const data = await res.json();

//			if (data?.error) {
//				showToast("Error", data.error, "error");
//				return;
//			}

			localStorage.removeItem("user-threads");
			localStorage.removeItem("pats_tkn");
			localStorage.removeItem("pats_usr");
			localStorage.removeItem("user-logged");
			setUser(null);
			setUserLogged(null);
			navigate('/', { replace: true });
		} catch (error) {
			showToast("Error", error, "error");
		}
	};
	return (
		<Button position={"fixed"} top={"20px"} right={"5px"} size={"sm"} onClick={handleLogout}>
			<FiLogOut size={20} />
			<Text fontSize="sm" >Log out</Text>
		</Button>
	);
};

export default LogoutButton;
