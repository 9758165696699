//import { useState } from 'react';
import {API_SALES_CUSTOMER, ENDPOINT_URL,
  STR_FILTER, STR_EQ ,TOKEN_KEY, USERID_KEY, FIELD_USERCUSTOMER_ID } from '../utility/constants';

export async function getSalesCustomer(searchText) {

      let token = localStorage.getItem(TOKEN_KEY);
      let userId = localStorage.getItem(USERID_KEY);

    // hOW TO AVOIDING fETCH net::ERR_CERT_AUTHORITY_INVALID
    
        const res = fetch(ENDPOINT_URL+API_SALES_CUSTOMER+'?'+
        STR_FILTER+FIELD_USERCUSTOMER_ID+' '+STR_EQ+' '+userId
        +(searchText && searchText.length !== 0 ? 'FacilitiesName'+' '+STR_EQ+' '+searchText:''), {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'Access-Encoding': 'gzip, deflate, br',
            'Connection': 'keep-alive',
            'authorization': 'Bearer '+token,
          }
        });

        return res;
}