export const ENDPOINT_PROTOCOL = 'https';
//export const ENDPOINT_HOST = 'localhost';
export const ENDPOINT_HOST = process.env.REACT_APP_ENDPOINT_HOST;
// Constant access point
//export const ENDPOINT_PORT = '8443';
export const ENDPOINT_PORT = process.env.REACT_APP_ENDPOINT_PORT;
export const ENDPOINT_BASE = '/api/v1';
export const ENDPOINT_URL = ENDPOINT_PROTOCOL + '://' + ENDPOINT_HOST + (ENDPOINT_PORT.length !== 0 ? ':':'') + ENDPOINT_PORT + ENDPOINT_BASE;
export const API_LOGIN = '/auth/tokens';
export const API_LOGOUT = '/auth/logout';
export const API_SALES_CUSTOMER = '/models/Pat_Sales_Customer_v';
export const API_LIST_PRICE = '/models/Pat_Sales';
export const API_LIST_PRICE_REPORT = '/processes/patfacilitiessales';
export const CLIENT_ID = 1000001;
export const ROLE_ID = 1000004;
export const ORGANIZATION_ID = 1000001;
export const WAREHOUSE_ID = 1000000;
//export const LANGUAGE = 'it_IT';
export const LANGUAGE = 'en_GB';

export const STR_FILTER = '$filter=';
export const STR_EQ = 'eq';
export const STR_EXPAND = '$expand=';
export const STR_ORDERBY = '$orderby=';

export const TABLE_SA_TREATMENT = 'Pat_SA_TreatmentFee';
export const TABLE_SA_ALLOTMENT = 'Pat_SA_AllotmentFee';
export const TABLE_SA_ADDITIONALBED = 'Pat_SA_AdditionalBedFee';
export const TABLE_SA_FACILITIESSERVICE = 'Pat_SA_FacilitiesService';
export const TABLE_SA_SERVICEONTREATMENT = 'Pat_SA_ServicesOnTreatment';
export const TABLE_SA_SPECIALOFFER = 'Pat_SA_SpecialOffers';
export const TABLE_SA_NOTES = 'Pat_SA_Notes';

export const FIELD_CUSTOMER_ID = 'BP_Customer_ID';
export const FIELD_USERCUSTOMER_ID = 'UserCustomer_ID';
export const FIELD_SALES_ID = 'Pat_Sales_ID';


export const TOKEN_KEY = 'pats_tkn';
export const USERID_KEY = 'pats_usr';