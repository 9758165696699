import './assets/css/App.css';
import { Routes, Route, Navigate } from 'react-router-dom';
import AuthLayout from './layouts/auth';
import AdminLayout from './layouts/admin';
import RTLLayout from './layouts/rtl';
import {
  ChakraProvider,
  // extendTheme
} from '@chakra-ui/react';
import initialTheme from './theme/theme'; //  { themeGreen }
import { useState } from 'react';
import { useRecoilValue } from "recoil";
import userAtom from "./atoms/userAtom";
import LoginCard from 'components/pats/LoginCard';
import HomePage from 'pages/pats/HomePage';
import FacilityDetails from 'pages/pats/FacilityDetails';
import HouseProvider from 'components/pats/HouseContext';
import FacilityDetails2 from 'pages/pats/FacilityDetails2';
// Chakra imports
import { Component } from 'react';

export default function Main() {
	const user = useRecoilValue(userAtom);
  const [currentTheme, setCurrentTheme] = useState(initialTheme); 
//  console.log("process.env:", process.env);
  return (
    <ChakraProvider theme={currentTheme}>
    <HouseProvider>
      <Routes>
{/*        <Route path="auth/*" element={<AuthLayout />} /> */}
{/*        <Route path="auth/*" element={!user ? <AuthLayout /> : <Navigate to='/' />}  /> */}
        <Route path="auth/*" element={!user ? <LoginCard /> : <Navigate to='/' />}  />
{/*        
        <Route
          path="admin/*"
          element={
            <AdminLayout theme={currentTheme} setTheme={setCurrentTheme} />
          }
        />
        <Route
          path="rtl/*"
          element={
            <RTLLayout theme={currentTheme} setTheme={setCurrentTheme} />
          }
        />
*/}        
        <Route
          path="HomePage/*"
          element={ user ? 
            <HomePage theme={currentTheme} setTheme={setCurrentTheme} /> :
            <Navigate to='/auth' />
          }
        />
{/*          <Route path='property-details' element={ <PropertyDetails /> } > */}
{/*            <Route path="property-details/:propertyId" element={<FacilityDetails />} />*/}
            <Route path="property-details/:propertyId" Component={FacilityDetails} />
            <Route path="property-details2/:propertyId" element={<FacilityDetails2 />} />
{/*          </Route>   */}
{/*        <Route path="/" element={<Navigate to="/admin" replace />} /> */}
{/*          <Route path='/' element={user ? <Navigate to='/admin' /> : <Navigate to='/auth' />} /> */}
          <Route path='/' element={user ? <Navigate to='/HomePage' /> : <Navigate to='/auth' />} />
{/*        <Route path='/' element={user ? <HomePage /> : <Navigate to='/auth' />} /> */}

      </Routes>
    </HouseProvider>
    </ChakraProvider>
  );
}
