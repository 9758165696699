import { Center, Grid, Heading, Spinner, Stack } from '@chakra-ui/react';
import { useContext } from "react";
import { Link } from 'react-router-dom';

import { HouseContext } from "./HouseContext";
import Facility from './Facility';
import Facility2 from './Facility2';

const Facilities = ({items}) => {
{/*  
  const { houses, isLoading } = useContext(HouseContext);

  if(isLoading){
    return (
      <Center>
        <Spinner align='center' color='pink.500' />
      </Center>
    )
  }
  if (houses.length === 0) {
    return (
      <Stack maxH='400px'>
        <Heading size="lg" p={{base: '6', md: '10'}} align="center">
          Oops... Can try another one?
        </Heading>
      </Stack>
    );
  }
*/ }
  return (
    <>
{/*}    
    <Grid my='3' rowGap='4' gridTemplateColumns='repeat(auto-fit, minmax(300px, 1fr))'>
      {houses.map(item => 
    <Link to={`/property-details2/${item.id}`} key={item.id}>
        <Facility2 key={item.id} facilityItem={item} />
      </Link>
      )}
    </Grid>
*/}    
    <Grid my='3' rowGap='4' gridTemplateColumns='repeat(auto-fit, minmax(300px, 1fr))'>
{/*      {items.map(item => <Link to={{pathname: `/property-details/${item.id}`, state:{item:item}}} facilityItem={item}>*/}
      {items.map(item => <Link to={`/property-details/${item.id}`} state={{item:item}}>
          <Facility key={item.id} facilityItem={item} />
        </Link>
        )}
      </Grid>
      </>
  )
}

export default Facilities;