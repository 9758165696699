import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import './assets/css/App.css';

import App from './App';
import { RecoilRoot } from "recoil";

const root = ReactDOM.createRoot(document.getElementById('root'));

root.render(
	// React.StrictMode renders every component twice (in the initial render), only in development.
	<React.StrictMode>
    <RecoilRoot>
      <BrowserRouter>
        <App />
      </BrowserRouter>,
    </RecoilRoot>
  </React.StrictMode>
);
