//import { useState } from 'react';
import {API_LIST_PRICE, API_LIST_PRICE_REPORT, ENDPOINT_URL, FIELD_SALES_ID, 
  STR_FILTER, STR_EQ,STR_ORDERBY, TOKEN_KEY, STR_EXPAND, TABLE_SA_SERVICEONTREATMENT, TABLE_SA_SPECIALOFFER, 
  TABLE_SA_NOTES, TABLE_SA_FACILITIESSERVICE} from '../utility/constants';

export async function getSalesListPrice(salesID) {

      let token = localStorage.getItem(TOKEN_KEY);
//      let userId = localStorage.getItem(USERID_KEY);

    // hOW TO AVOIDING fETCH net::ERR_CERT_AUTHORITY_INVALID
    
        const res = fetch(ENDPOINT_URL+API_LIST_PRICE+'?'+
        STR_FILTER+FIELD_SALES_ID+' '+STR_EQ+' '+salesID+'&'+STR_EXPAND+TABLE_SA_SERVICEONTREATMENT+'('+STR_ORDERBY+'SeqNo),'
        +TABLE_SA_SPECIALOFFER+'('+STR_ORDERBY+'SeqNo),'+TABLE_SA_NOTES+'('+STR_FILTER+'type '+STR_EQ+' \'N\';'
        +STR_ORDERBY+'SeqNo),'
        +TABLE_SA_FACILITIESSERVICE+'('+STR_ORDERBY+'Type, SeqNo)', {
          method: 'GET',
          headers: {
            'Content-Type': 'application/json',
            'Accept': '*/*',
            'Access-Encoding': 'gzip, deflate, br',
            'Connection': 'keep-alive',
            'authorization': 'Bearer '+token,
          }
        });

        return res;
}

export async function getSalesListPriceReport(salesID) {

  let token = localStorage.getItem(TOKEN_KEY);
//      let userId = localStorage.getItem(USERID_KEY);

// hOW TO AVOIDING fETCH net::ERR_CERT_AUTHORITY_INVALID

    const res = fetch(ENDPOINT_URL+API_LIST_PRICE_REPORT, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
        'Accept': '*/*',
        'Access-Encoding': 'gzip, deflate, br',
        'Connection': 'keep-alive',
        'authorization': 'Bearer '+token,
      },
      body: JSON.stringify(
        {
          'record-id': salesID
        }					
        )
  });

    return res;
}